/*border: 1px solid black;*/
.App {
  text-align: center;
  height: 100%;
  width: 100%;
}

.App-link {
  color: #61dafb;
}

.img-gallery-div {
	margin: auto;
    width: 70%;
  /*padding-left: 200px;
  padding-right: 200px;*/
}

.chapter {
  width: 60%;
  margin: auto;
  position: relative;
  /*border: 1px solid black;*/
  text-align: left;
}

.chapter h1{
  position: relative;
  font-size: 34px;
  font-family: Verdana, sans-serif;
  color: #4a5f43;
  font-weight: 400;
  padding-top: 12px;
  text-align: left;
}

.chapter h2{
  position: relative;
  font-size: 28px;
  font-family: Verdana, sans-serif;
  color: #4a5f43;
  font-weight: 400;
  padding-top: 12px;
  text-align: left;
}

.chapter p{
  line-height: 25px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 8px;
}

.header-cont-div {
  width: 100%;
  height: 100%;
  max-height: 1179;
  overflow: hidden;
}
.logo-div {
  height: 150px;
  margin-top: -20px;
  margin-left: 10px;
}
.logo-img {
  width: 350px;
  height: 150px;
  object-fit: none;
  margin: 2px;
}

.header-cont-img {
  width: 100%;
}

.static {
  margin: 0;
  padding: 0;
  
  background-attachment: fixed;
  background-repeat: 'no-repeat';
  background-size: cover;
  height: 250px;
  background-image: 
    linear-gradient(
      /*
      rgba(73, 91, 67, 0.5),
      rgba(73, 91, 67, 0.5)
    */
      rgba(100, 100, 100, 0.5),
      rgba(100, 100, 100, 0.5)
    ),
    url(./imgs/bg.jpg);
}
.s1 {
  font-weight: bold;
  color: #000;
}
.footer-link {
  display:block;
  margin:0 0 0 1px;
  padding:3px 10px;
  /*background:#ddd;*/
  color:#000;
  text-decoration:none;
  line-height:1.3em;
}

.footer-link:hover {
  background:#4a5f43;
  color:#fff;
}

.link-container {
position: relative;
}

.link-container-div {
  width: 50%;
  position:inherit;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nav {
  float:left;
  width:100%;
  background:#fff;
  border-bottom:4px solid #000;
  overflow:hidden;
  position:relative;
}

.nav-ul  {
    clear:left;
    float:left;
    list-style:none;
    margin:0;
    padding:0;
    position:relative;
    left:50%;
    text-align:center;
 }

 .nav .nav-ul .nav-li {
  display:block;
  float:left;
  list-style:none;
  margin:0;
  padding:0;
  position:relative;
  right:50%;
}

.nav .nav-ul .nav-li a {
  display:block;
  margin:0 0 0 1px;
  padding:3px 10px;
  /*background:#ddd;*/
  color:#000;
  text-decoration:none;
  line-height:1.3em;
}

.nav .nav-ul .nav-li a:hover {
  background:#4a5f43;
  color:#fff;
}

.nav .nav-ul .nav-li a.active,
.nav .nav-ul .nav-li a.active:hover {
  color:#fff;
  background:#000;
  font-weight:bold;
}

.mapouter{
  position: relative;
  text-align: left;
  height: 60vh;
  width: 100%;
}

  .gmap_canvas {
  overflow: hidden;
  background: none!important;
  height: 100%;
  width: 100%;
}


.gmap_main{
  height: 100%;
  width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@import "~react-image-gallery/styles/css/image-gallery.css";